import React from 'react';

import solana from '../../assets/img/Clip path group.svg';
import arrow from '../../assets/img/image 2 (Traced).svg';
import VideoCard3D from '../../components/VideoCard3D';

import './css/style.css';
import { BASE_PROFIT } from '../../constants';
import { ConnectWallet } from '../ConnectWallet';

const AppLevelView = ({
  levels,
  toggleOpen,
  handleLevelClick,
  handleBuyLevel,
  handleClaimProfit,
  activeLevel,
  activeLevelData,
  levelsRef,
  popupRef,
  isOpen,
  isLoading,
  setIsLoading,
  publicKey,
}) => {
  const icClaimButtonAvailable =
    publicKey &&
    activeLevelData.purchased &&
    !activeLevelData.isClaimed &&
    Number(activeLevelData.profitAmount) > 0;

  const isBuyButtonAvailable = publicKey && !activeLevelData.purchased;
  return (
    <div className="app-level">
      <div className="app-level__container">
        <div className="app-level__game">
          <VideoCard3D activeLevel={activeLevel} isLoading={isLoading} setIsLoading={setIsLoading} />
          <div className="app-level__game-info">
            <ul className="app-level__game-list">
              <li className="app-level__game-item">
                <span className="app-level__game-text">
                  <span className="app-level__highlight">Yield per cycle:</span>
                </span>
                <span className="game-text__bold app-level__game-text ">
                  <img src={solana} alt="Solana" className="app-level__game-icon" />
                  {` `}
                  {(activeLevelData.price * BASE_PROFIT).toFixed(2)}
                  {` `}
                  <span className="app-level__game-text">SOL</span>
                </span>
              </li>
              <li className="app-level__game-item">
                <span className="app-level__game-text">
                  <span className="app-level__highlight">Referral income per cycle:</span>
                </span>
                <span className="game-text__bold app-level__game-text ">
                  <img src={solana} alt="Solana" className="app-level__game-icon" />
                  {` `}
                  {(activeLevelData.price * activeLevelData.defaultRate).toFixed(2)}
                  {` `}
                  <span className="app-level__game-text">SOL</span>
                </span>
              </li>
              {/* {activeLevelData.purchased && (
                <li className="app-level__game-item">
                  <span className="app-level__game-text  app-level__game-text-missed">Missed:</span>
                  <span className="game-text__bold app-level__game-text ">
                    <img src={solana} alt="Solana" className="app-level__game-icon" />
                    {` `}
                    {activeLevelData.missed} <span className="app-level__game-text">SOL</span>
                  </span>
                </li>
              )} */}
            </ul>
          </div>

          <div className="app-level__game-btns">
            {!publicKey && <ConnectWallet />}

            {isBuyButtonAvailable && (
              <button className="app-level__game-btn" type="button" onClick={handleBuyLevel}>
                BUY
              </button>
            )}

            {icClaimButtonAvailable && (
              <button className="app-level__game-btn" type="button" onClick={handleClaimProfit}>
                CLAIM
              </button>
            )}
          </div>
        </div>
        <div className="app-level__levels">
          <ul className="app-level__levels-list">
            {levels.map((level) => (
              <li
                key={level.id}
                className={`app-level__levels-item ${!level.isActive ? 'close-levels' : ''} ${
                  activeLevel === level.id ? 'active-lvl' : ''
                }`}
                onClick={() => handleLevelClick(level)}
              >
                <span
                  className={`app-level__levels-text app-level__levels-title ${
                    !level.isActive ? 'close' : ''
                  }`}
                >
                  <span
                    style={{
                      display: !level.isActive ? 'none' : 'inline-block',
                    }}
                    className={`checkbox-circle ${level.purchased ? 'checked' : ''} ${
                      !level.isActive ? 'disabled' : ''
                    }`}
                  />
                  Pool {level.id}
                </span>
                {level.purchased && (
                  <span className="app-level__levels-text app-level__levels-time">
                    {`${level.profitAmount}/${level.maxProfitAmount} SOL (${level.percentUntilNextPayout}%)`}
                  </span>
                )}
                {level.price && (
                  <span className="app-level__levels-text app-level__levels-time">
                    {`${level.price} SOL | `}
                    {level.isActive ? 'OPEN' : 'CLOSED'}
                  </span>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="app-level__levels-phone">
          <div className="app-level__levels-popup" onClick={toggleOpen} ref={popupRef}>
            <span className="app-level__levels-text">Level {activeLevel}</span>
            <span className="app-level__levels-text othet">
              <img
                src={arrow}
                alt="Solana"
                className="app-level__game-icon"
                style={{
                  transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s ease',
                }}
              />
              Other
            </span>
          </div>
          <div
            className="app-level__levels-mobile"
            style={{
              height: isOpen ? '361px' : '0px',
              overflow: 'hidden',
              transition: 'height 0.5s ease',
            }}
            ref={levelsRef}
          >
            <ul className="app-level__levels-list-mobile">
              {levels.map((level) => (
                <li
                  key={level.id}
                  className={`app-level__levels-item-mobile ${!level.isActive ? 'close-levels-mobile' : ''} ${
                    activeLevel === level.id ? 'active-lvl' : ''
                  }`}
                  onClick={() => handleLevelClick(level)}
                >
                  <div
                    className={`app-level__levels-text-mobile app-level__levels-title-mobile ${
                      !level.isActive ? 'close' : ''
                    }`}
                  >
                    <span
                      style={{
                        display: !level.isActive ? 'none' : 'inline-block',
                      }}
                      className={`checkbox-circle ${level.purchased ? 'checked' : ''} ${
                        !level.isActive ? 'disabled' : ''
                      }`}
                    ></span>
                    Level {level.id}
                  </div>
                  {level.price && (
                    <div
                      className={`app-level__levels-text-mobile ${
                        !level.isActive ? 'app-level__levels-time-mobile' : ''
                      }`}
                    >
                      {`${level.price} SOL | `}
                      {level.isActive ? 'OPEN' : 'CLOSED'}
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLevelView;
