import defaultAxios from 'axios';
import { jwtDecode } from 'jwt-decode';

import { COOKIE_AUTH } from '../constants';
import { setCookie } from './cookie';

export const authDecode = (raw) => jwtDecode(raw);

export const AUTH_HEADER = 'Authorization';
export const WALLET_HEADER = 'Wallet';

const transformResponse = (data, headers) => {
  if (headers['content-type'] === 'text/plain; charset=UTF-8') return data;
  if (!data) {
    return null;
  }
  data = JSON.parse(data);
  return data;
};

export const axios = defaultAxios.create({
  timeout: 100000,
  transformResponse: [transformResponse],
  baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API : '/',
});

export const setAuthorizationHeader = (tokenHash) => {
  if (tokenHash === null) {
    delete axios.defaults.headers.common[AUTH_HEADER];
  } else {
    const token = `Bearer ${tokenHash}`;

    axios.defaults.headers.common[AUTH_HEADER] = token;
  }
};

export const setWalletHeader = (wallet) => {
  if (wallet === null) {
    delete axios.defaults.headers.common[WALLET_HEADER];
  } else {
    axios.defaults.headers.common[WALLET_HEADER] = wallet;
  }
};

export const setAuthorization = (token) => {
  setAuthorizationHeader(token);
  setCookie(COOKIE_AUTH, token);
};

const onResponseSuccess = (response) => {
  return response;
};

const onResponseError = (error) => {
  if (!error.response) {
    error.response = { data: { message: '' } };
  }

  if (error.response.status === 401) {
    setAuthorization(null);
    window.location.reload();
  }

  return Promise.reject(error);
};

axios.interceptors.response.use(onResponseSuccess, onResponseError);
