import React, { useEffect } from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';

import { showToastError } from '../../components/ToastPopup';
import { useUser } from '../UserContext';

export const ConnectWallet = () => {
  const { connected, disconnect } = useWallet();
  const { user } = useUser();

  useEffect(() => {
    const originalConsoleError = console.error;

    console.error = (...args) => {
      if (args.some((arg) => arg instanceof Error && arg.name === 'WalletNotReadyError')) {
        disconnect();
        showToastError('Wallet Not Ready. Please install the wallet extension.');
      }
      originalConsoleError(...args);
    };

    return () => {
      console.error = originalConsoleError;
    };
  }, [disconnect]);

  return (
    <div className={connected ? 'wallet-connected' : 'wallet-not-connected'}>
      <WalletMultiButton>
        {connected ? (user?.balance ? `${user?.balance} SOL` : 'Loading...') : 'Connect Wallet'}
      </WalletMultiButton>
    </div>
  );
};

export default ConnectWallet;
