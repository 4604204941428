import React from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { Link } from 'react-router-dom';

import { showToastSuccess } from '../../components/ToastPopup';
import './css/style.css';

import avatar from '../../assets/img/Запись экрана 2024-12-03 в 17.07.52 1.svg';
import arrow from '../../assets/img/image 3 (Traced).svg';
import sol from '../../assets/img/Clip path group.svg';
import { formatAddress } from '../../utils/web3';
import { historyData, referralData } from '../../data/data';
import { useUser } from '../UserContext';

const AppAccount = () => {
  const { publicKey } = useWallet();

  const handleCopyReferralLink = () => {
    const referralLink = `${window.location.hostname}`;
    navigator.clipboard.writeText(referralLink).then(() => {
      showToastSuccess('Referral link copied successfully!');
    });
  };

  const { user } = useUser();

  return (
    <div className="app-account">
      <div className="app-account__container">
        <div className="app-account__info">
          <div className="app-account__info-user">
            <img src={avatar} alt="User" className="app-account__info-avatar" />
            <div className="app-account__info-box">
              <div className="app-account__info-address">
                <span className="app-account__info-text">Address:</span>
                <span className="app-account__info-text">
                  {publicKey ? formatAddress(publicKey) : 'Not connected'}
                </span>
              </div>
              <div className="app-account__info-profit">
                <span className="app-account__info-text">
                  Total
                  <span className="app-level__highlight">Profit:</span>
                </span>
                <span className="app-account__info-text">
                  <img src={sol} alt="Solana" className="app-account__info-icon" />
                  <span className="app-account__info-text-bold">157,05</span>
                  SOL
                </span>
              </div>
              {/* <div className="app-level__game-item">
                <span className="app-level__game-text  app-level__game-text-missed">Missed:</span>
                <span className="app-account__info-text">
                  <img src={sol} alt="Solana" className="app-account__info-icon" />
                  <span className="app-account__info-text-bold">7,05</span>
                  SOL
                </span>
              </div> */}
            </div>
          </div>
          <div className="app-account__info-history">
            <div className="app-account__info-history-title">
              <span className="app-account__info-text-title">History:</span>
            </div>

            <ul className="app-account__info-history-list">
              {historyData.map((item, index) => (
                <li className="app-account__info-history-item" key={index}>
                  <span className="app-account__info-text">{formatAddress(item.address)}</span>{' '}
                  <a href="" className="app-account__info-text app-account__info-text-highlight">
                    {item.action}
                    <img src={arrow} alt="Solana" className="app-account__info-icon" />
                  </a>
                  <span className="app-account__info-text">{item.time}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="app-account__referral">
          <div className="app-account__referral-program">
            <div className="app-account__referral-title">
              <span className="app-account__info-text-title">Referral Program:</span>
            </div>
            <div className="app-account__referral-text">
              <span className="app-account__info-text">Your referral link:</span>
              {user && (
                <span className="app-account__info-text">{`${window.location.hostname}?ref=${user.uuid}`}</span>
              )}
              {user ? (
                <div className="app-account__referral-button" onClick={handleCopyReferralLink}>
                  Copy Referral Link
                </div>
              ) : (
                <Link to="/levels">
                  <div className="app-account__referral-button">Join any pool to get referral link</div>
                </Link>
              )}
            </div>
            <span className="app-account__referral-profit-referals">
              <span className="app-account__info-text">Total Profit from Referrals:</span>
              <span className="app-account__info-text">
                <span className="app-account__info-text-bold">157,05</span> SOL
              </span>
            </span>
            <div className="app-account__referral-invited">
              <span className="app-account__info-text">Invited:</span>
              <span className="app-account__info-text">2 Users</span>
            </div>
          </div>
          <div className="app-account__your-referral">
            <div className="app-account__referral-title">
              <span className="app-account__info-text-title">Activity Of Your Referrals</span>
            </div>
            <div className="app-account__referral-table">
              <div className="app-account__referral-table-header">
                <span className="app-account__referral-table-header-item">Address</span>
                <span className="app-account__referral-table-header-item">Total Profit</span>
                <span className="app-account__referral-table-header-item">Invited Users</span>
              </div>
              <div className="app-account__referral-table-body">
                {referralData.map((item, index) => (
                  <div className="app-account__referral-table-row" key={index}>
                    <span className="app-account__referral-table-item">{formatAddress(item.address)}</span>
                    <span className="app-account__referral-table-item">{item.totalProfit}</span>
                    <span className="app-account__referral-table-item">
                      {item.invitedUsers}
                      <span className="app-account__referral-table-item-margin" />
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppAccount;
