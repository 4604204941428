import Spline from '@splinetool/react-spline';
import React, { useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { splineUrls, gifUrls } from '../../data/data';

const VideoCard3D = ({ isLoading, activeLevel, setIsLoading }) => {
  const [isGif, setIsGif] = useState(false);

  const handleLoad = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  useEffect(() => {
    const updateUrl = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 750) {
        setIsGif(true);
      } else {
        setIsGif(false);
      }
    };

    updateUrl();
    window.addEventListener('resize', updateUrl);

    return () => {
      window.removeEventListener('resize', updateUrl);
    };
  }, [activeLevel]);

  useEffect(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 750) {
      setIsGif(true);
    } else {
      setIsGif(false);
    }
  }, [activeLevel, isGif]);

  return (
    <>
      {isLoading && (
        <div
          className="app-level__game-loader"
          style={{
            width: '750px',
            height: '400px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#DE25CB"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#DE25CB"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
      {isGif ? (
        <video
          src={gifUrls[activeLevel]}
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: '100%',
            height: '100%',
            visibility: isLoading ? 'hidden' : 'visible',
            display: isLoading ? 'none' : 'block',
          }}
          onLoadedData={() => setIsLoading(false)}
          onError={(error) => {
            console.error('Failed to load video:', error);
            setIsLoading(false);
          }}
        />
      ) : (
        <Spline
          className="app-level__game-spline"
          style={{
            width: '750px',
            height: '400px',
            visibility: isLoading ? 'hidden' : 'visible',
            display: isLoading ? 'none' : 'flex',
          }}
          scene={splineUrls[activeLevel]}
          onLoad={handleLoad}
          onError={(error) => {
            console.error('Failed to load Spline scene', error);
            setIsLoading(false);
          }}
        />
      )}
    </>
  );
};

export default VideoCard3D;
