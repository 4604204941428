import { useMemo } from 'react';
import { clusterApiUrl, PublicKey, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { PhantomWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets';
import { BackpackWalletAdapter } from '@solana/wallet-adapter-backpack';
import { Program, AnchorProvider } from '@coral-xyz/anchor';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import idl from '../lottery.json';

/* Constants for RPC Connection the Solana Blockchain */
export const commitmentLevel = 'processed';
export const endpoint = clusterApiUrl('devnet');
export const wallets = [new PhantomWalletAdapter(), new SolflareWalletAdapter(), new BackpackWalletAdapter()];
export const emptyPubkey = new PublicKey('11111111111111111111111111111111');

const USER_TAG = Buffer.from('USER');
const TREE_USER_TAG = Buffer.from('TREE_USER');
const TREE_TAG = Buffer.from('TREE');
const CELL_TAG = Buffer.from('CELL');
const SUFFIX_TAG = Buffer.from('+');

export const formatAddress = (address) => {
  if (!address) return '';
  const addressStr = address.toString();
  return `${addressStr.slice(0, 6)}...${addressStr.slice(-4)}`;
};

export const getSolanaValue = (value) => (value / LAMPORTS_PER_SOL).toFixed(2);

export function i16ToBytesLE(value) {
  const buffer = new ArrayBuffer(4); // Allocate 2 bytes for the i16 value
  const view = new DataView(buffer);
  view.setUint32(0, value, true); // Set the value in little-endian format
  return new Uint8Array(buffer);
}

export const useSolanaProgram = () => {
  const { publicKey, wallet } = useWallet();
  const { connection } = useConnection();

  const program = useMemo(() => {
    if (!wallet || !publicKey) {
      return new Program(idl, { connection });
    }

    const provider = new AnchorProvider(connection, wallet, { commitment: 'processed' });

    return new Program(idl, provider);
  }, [wallet, publicKey, connection]);

  const getTreePda = (id) => {
    const [treePda] = PublicKey.findProgramAddressSync(
      [TREE_TAG, SUFFIX_TAG, i16ToBytesLE(id)],
      program.programId,
    );

    return treePda;
  };

  const getTreeUserPda = (id) => {
    const [treePda] = PublicKey.findProgramAddressSync(
      [TREE_USER_TAG, SUFFIX_TAG, i16ToBytesLE(id), SUFFIX_TAG, publicKey.toBuffer()],
      program.programId,
    );

    return treePda;
  };

  const getUserPda = (key) => {
    const [userPda] = PublicKey.findProgramAddressSync(
      [USER_TAG, SUFFIX_TAG, key ? key.toBuffer() : publicKey.toBuffer()],
      program.programId,
    );

    return userPda;
  };

  const getStatePda = () => {
    const [statePda] = PublicKey.findProgramAddressSync([], program.programId);

    return statePda;
  };

  const getCellPda = (treeId, cellId) => {
    const [cellPda] = PublicKey.findProgramAddressSync(
      [CELL_TAG, SUFFIX_TAG, i16ToBytesLE(cellId), SUFFIX_TAG, i16ToBytesLE(treeId)],
      program.programId,
    );

    return cellPda;
  };

  return { program, connection, wallets, getTreePda, getTreeUserPda, getUserPda, getStatePda, getCellPda };
};
