import { Link, useLocation } from 'react-router-dom';

import React, { useState, useEffect, useRef } from 'react';
import './css/style.css';
import logo from '../../assets/img/Solnet_text_logo_main 1 (Traced).svg';
import burger from '../../assets/img/but 1.svg';
import ConnectWallet from '../../containers/ConnectWallet';

const AppHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const headerRef = useRef(null);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (
      isMenuOpen &&
      !event.target.closest('.app-header__nav-list') &&
      !event.target.closest('.burger-menu')
    ) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMenuOpen]);

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <header className="app-header" id="app-header" ref={headerRef}>
      <div className="app-header__logo-box">
        <Link to="/">
          <img src={logo} alt="Docplanner Group" className="app-header__logo" />
        </Link>
        <div className="app-header__nav-item ">
          <ConnectWallet className="app-header__nav-link btn-connect-wallet" />
        </div>
      </div>
      <nav className={`app-header__nav ${isMenuOpen ? 'open' : ''}`}>
        <ul className="app-header__nav-list">
          <li className="app-header__nav-item">
            <Link to="/levels" className="app-header__nav-link" onClick={handleLinkClick}>
              Levels
            </Link>
          </li>
          <li className="app-header__nav-item">
            <Link to="/account" className="app-header__nav-link" onClick={handleLinkClick}>
              ACCOUNT
            </Link>
          </li>
          <li className="app-header__nav-item">
            <Link to="/statistics" className="app-header__nav-link" onClick={handleLinkClick}>
              STATISTICS
            </Link>
          </li>
          <li className="app-header__nav-item">
            <ConnectWallet className="app-header__nav-link btn-connect-wallet" />
          </li>
        </ul>
      </nav>

      <div className="burger-menu" onClick={toggleMenu}>
        <img src={burger} alt="Burger Menu" />
      </div>
    </header>
  );
};

export default AppHeader;
