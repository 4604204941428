import React, { useState, useEffect, useRef } from 'react';
import { Transaction, SystemProgram } from '@solana/web3.js';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import { showToastSuccess, showToastError } from '../../components/ToastPopup';
import { useSolanaProgram, getSolanaValue, emptyPubkey } from '../../utils/web3';

import { LEVELS, BASE_PROFIT, treasury } from '../../constants';
import AppLevelView from './View';
import { useUser } from '../UserContext';
import { axios } from '../../utils/axios';

const AppLevel = () => {
  const { program, getTreeUserPda, getUserPda, getStatePda, getTreePda, getCellPda } = useSolanaProgram();

  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [levels, setLevels] = useState(LEVELS);
  const [userLevels, setUserLevels] = useState([]);
  const [activeLevel, setActiveLevel] = useState(1);

  const { connection } = useConnection();

  const levelsRef = useRef(null);
  const popupRef = useRef(null);

  const { publicKey, connected, sendTransaction } = useWallet();

  const { user } = useUser();

  const getPerformedLevels = () => {
    if (userLevels.length) {
      return userLevels;
    }

    return levels;
  };

  const performedLevels = getPerformedLevels();

  const activeLevelData = performedLevels[activeLevel - 1];

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const fetchLevels = async () => {
    try {
      const fetchedLevels = await Promise.all(
        LEVELS.map(async (level) => {
          const treeData = await program.account.tree.fetch(getTreePda(level.id));

          return {
            ...treeData,
            price: getSolanaValue(treeData.price),
            defaultRate: Number(getSolanaValue(treeData.defaultRate)),
          };
        }),
      );

      setLevels(fetchedLevels);
    } catch (error) {
      console.error(error);

      showToastError('Failed to fetch pools.');
    }
  };

  const fetchUserLevels = async () => {
    const fetchedLevels = await Promise.all(
      levels.map(async (level) => {
        const currentLevel = levels.find((item) => item.id === level.id);

        try {
          const treeUserData = await program.account.treeUser.fetch(getTreeUserPda(level.id));

          const cellData = await program.account.cell.fetch(getCellPda(level.id, treeUserData.lastCell));

          const isClaimed = Number(getSolanaValue(cellData.amount)) === 0;

          const nextPayoutCell = treeUserData.lastCell * 2;
          const levelLastCell = level.lastCellId;

          let isPurchased = nextPayoutCell > levelLastCell - 2;

          if (!isPurchased && !isClaimed) {
            isPurchased = true;
          }

          console.log(isClaimed);

          let profitAmount = 0;

          const isFirstProfitReady = nextPayoutCell < levelLastCell;

          if (isFirstProfitReady) {
            profitAmount += level.price * BASE_PROFIT;
          }

          const isSecondProfitReady = nextPayoutCell < levelLastCell - 1;

          if (isSecondProfitReady) {
            profitAmount += level.price * BASE_PROFIT;
          }

          const cellsRemaining = nextPayoutCell - levelLastCell + 2;
          const percentUntilNextPayout = 100 - (cellsRemaining / nextPayoutCell) * 100;

          profitAmount = profitAmount.toFixed(2);

          return {
            ...currentLevel,
            initted: !!treeUserData.treeId,
            lastUserCellId: treeUserData.lastCell,
            purchased: isPurchased,
            profitAmount,
            maxProfitAmount: (level.price * BASE_PROFIT * 2).toFixed(2),
            percentUntilNextPayout: percentUntilNextPayout.toFixed(0),
            isClaimed,
            isFirstProfitReady,
            isSecondProfitReady,
          };
        } catch (e) {
          return {
            ...currentLevel,
            initted: false,
          };
        }
      }),
    );

    setUserLevels(fetchedLevels);
  };

  const handleClaimProfit = async () => {
    try {
      if (activeLevelData.isClaimed) {
        return showToastError('Profit already claimed');
      }

      if (!Number(activeLevelData.profitAmount)) {
        return showToastError('No profit to claim');
      }

      const transaction = new Transaction();

      if (activeLevelData.isFirstProfitReady) {
        const firstProfitParentCell = activeLevelData.lastUserCellId * 2;

        const initUserInstruction = await program.methods
          .claimCell()
          .accounts({
            cell: getCellPda(activeLevelData.id, activeLevelData.lastUserCellId),
            parentCell: getCellPda(activeLevelData.id, firstProfitParentCell),
            user: publicKey,
            systemProgram: SystemProgram.programId, // Системная программа (если нужно для инициализации)
          })
          .instruction();

        transaction.add(initUserInstruction);
      }

      if (activeLevelData.isSecondProfitReady) {
        const secondProfitParentCell = activeLevelData.lastUserCellId * 2 + 1;

        console.log(secondProfitParentCell, activeLevelData);

        const initUserInstruction = await program.methods
          .claimCell()
          .accounts({
            cell: getCellPda(activeLevelData.id, activeLevelData.lastUserCellId),
            parentCell: getCellPda(activeLevelData.id, secondProfitParentCell),
            user: publicKey,
            systemProgram: SystemProgram.programId, // Системная программа (если нужно для инициализации)
          })
          .instruction();

        transaction.add(initUserInstruction);
      }

      const transactionSignature = await sendTransaction(transaction, connection);

      showToastSuccess(
        `Reward from pool #${activeLevelData.id} claim successfully! View on explorer: https://solana.fm/tx/${transactionSignature}?cluster=devnet-alpha`,
      );
    } catch (e) {
      console.error(e);

      showToastError('Failed to claim reward.');
    }
  };

  useEffect(() => {
    fetchLevels();
  }, []);

  useEffect(() => {
    fetchUserLevels();
  }, [connected, publicKey, levels, user]);

  const handleLevelClick = (level) => {
    if (level.isActive) {
      setIsLoading(true);
      setActiveLevel(level.id);
      setIsOpen(false);
    }
  };

  const handleBuyLevel = async () => {
    try {
      if (!activeLevelData) {
        return showToastError('Pool doesnt exist');
      }

      if (activeLevelData.purchased) {
        return showToastError('Pool aready purchased');
      }

      if (performedLevels[activeLevel - 2] && !performedLevels[activeLevel - 2].initted) {
        return showToastError('You should join previous pool first');
      }

      const transaction = new Transaction();

      if (!user) {
        const initUserInstruction = await program.methods
          .initUser()
          .accounts({
            state: getStatePda(),
            user: publicKey,
            systemProgram: SystemProgram.programId, // Системная программа (если нужно для инициализации)
          })
          .instruction();

        transaction.add(initUserInstruction);
      }

      if (!activeLevelData.initted) {
        const initTreeUserInstruction = await program.methods
          .initTreeUser()
          .accounts({
            treeUser: getTreeUserPda(activeLevelData.id), // Укажите ключи аккаунтов
            tree: getTreePda(activeLevelData.id), // Укажите дерево
            user: publicKey, // Укажите авторизацию (если нужно)
            systemProgram: SystemProgram.programId, // Системный аккаунт
          })
          .instruction();

        transaction.add(initTreeUserInstruction);
      }

      const {
        data: { referrer: userReferrer },
      } = await axios.get('/user/referrer');

      const buyLevelInstruction = await program.methods
        .initCell(userReferrer.publicKey || emptyPubkey)
        .accounts({
          tree: getTreePda(activeLevelData.id),
          userAccount: getUserPda(),
          referralUserAccount: getUserPda(userReferrer.publicKey || emptyPubkey),
          treeUser: getTreeUserPda(activeLevelData.id),
          state: getStatePda(),
          user: publicKey,
          treasury,
          systemProgram: SystemProgram.programId,
        })
        .instruction();

      transaction.add(buyLevelInstruction);

      const transactionSignature = await sendTransaction(transaction, connection);

      showToastSuccess(
        `Pool #${activeLevelData.id} purchased successfully! View on explorer: https://solana.fm/tx/${transactionSignature}?cluster=devnet-alpha`,
      );

      await fetchUserLevels();
    } catch (e) {
      console.error(e);

      showToastError('Failed to buy pool.');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        levelsRef.current &&
        !levelsRef.current.contains(event.target) &&
        popupRef.current &&
        !popupRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <AppLevelView
      levels={performedLevels}
      toggleOpen={toggleOpen}
      handleBuyLevel={handleBuyLevel}
      handleClaimProfit={handleClaimProfit}
      handleLevelClick={handleLevelClick}
      activeLevel={activeLevel}
      activeLevelData={activeLevelData}
      levelsRef={levelsRef}
      popupRef={popupRef}
      isOpen={isOpen}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      publicKey={publicKey}
    />
  );
};

export default AppLevel;
