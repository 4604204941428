import React, { useEffect } from 'react';

import { Route, Routes } from 'react-router-dom';
import About from './components/About';
import AppHeader from './components/AppHeader';
import AppLevel from './containers/AppLevel';
import Footer from './components/Footer';
import Header from './components/Header';
import LastWinner from './components/LastWinner';
import Questions from './components/Questions';
import RoadMap from './components/RoadMap';
import WelcomeComponent from './components/WelcomComponent';
import AppAccount from './containers/AppAccount';
import AppStatistics from './components/AppStatistics';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import './assets/css/style.css';

import ToastNotification from './components/ToastPopup';
import Head from './components/Head/head';

import { WalletProviderContainer } from './containers/WalletProvider';
import { UserProvider } from './containers/UserContext';
import AuthProvider from './containers/AuthProvider';

export const PageWrapper = ({ children }) => {
  return (
    <AuthProvider>
      <AppHeader />
      <LastWinner />
      {children}
    </AuthProvider>
  );
};

function App() {
  return (
    <div className="App">
      <Head />
      <WalletProviderContainer>
        <UserProvider>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <div className="Welcom">
                    <Header />
                    <WelcomeComponent />
                  </div>
                  <About />
                  <RoadMap />
                  <Questions />
                  <Footer />
                </>
              }
            />

            <Route
              path="/levels"
              element={
                <PageWrapper>
                  <AppLevel />
                </PageWrapper>
              }
            />
            <Route
              path="/account"
              element={
                <PageWrapper>
                  <AppAccount />
                </PageWrapper>
              }
            />
            <Route
              path="/statistics"
              element={
                <PageWrapper>
                  <AppStatistics />
                </PageWrapper>
              }
            />
          </Routes>
        </UserProvider>
      </WalletProviderContainer>
      <ToastNotification />
    </div>
  );
}

export default App;
