import { useEffect } from 'react';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';

import { showToastError } from '../../components/ToastPopup';
import { getSolanaValue, useSolanaProgram } from '../../utils/web3';
import { axios, setAuthorization, setAuthorizationHeader, setWalletHeader } from '../../utils/axios';

import { COOKIE_AUTH, COOKIE_REF } from '../../constants';
import { useUser } from '../UserContext';
import { getCookie, setCookie } from '../../utils/cookie';

const AuthProvider = ({ children }) => {
  const { program, getUserPda } = useSolanaProgram();

  const { user, setUser } = useUser();

  const { connection } = useConnection();

  const { publicKey, connected, signMessage } = useWallet();

  const fetchBalance = async () => {
    try {
      const rawBalance = await connection.getBalance(publicKey);

      setUser({ ...user, balance: getSolanaValue(rawBalance) });
    } catch (error) {
      showToastError('Failed to fetch wallet balance.');
    }
  };

  const fetchUser = async () => {
    try {
      let user = {};

      try {
        const userProgram = await program.account.user.fetch(getUserPda());

        if (userProgram) {
          user = { ...userProgram };
        }
      } catch {}

      const {
        data: { user: apiUser },
      } = await axios.get('/user');

      user = { ...user, ...apiUser };

      setUser(user);
    } catch (error) {
      showToastError('Failed to fetch user');
    }
  };

  useEffect(() => {
    const authCookie = getCookie(COOKIE_AUTH);

    if (authCookie) {
      setAuthorizationHeader(authCookie);
    }
  }, []);

  const loadUser = async () => {
    const auth = getCookie(COOKIE_AUTH);

    if (publicKey && connected && auth) {
      setWalletHeader(publicKey);
      await fetchUser();
      await fetchBalance();
    }
  };

  const handleMessage = async () => {
    try {
      const message = new TextEncoder().encode(
        `Авторизация на платформе. Время: ${new Date().toISOString()}`,
      );

      const signature = await signMessage(message);

      const signPayload = {
        publicKey: publicKey.toBase58(),
        message: Buffer.from(message).toString('hex'),
        signature: Buffer.from(signature).toString('hex'),
        referrer: getCookie(COOKIE_REF),
      };

      const { data } = await axios.post('/verify-signature', signPayload);

      setAuthorization(data.token);

      await loadUser();
    } catch (error) {
      showToastError('Error while authorizing');
    }
  };

  useEffect(() => {
    if (publicKey) {
      setWalletHeader(publicKey.toString());
    }

    const auth = getCookie(COOKIE_AUTH);

    if (publicKey && connected && !auth) {
      handleMessage();
    }
  }, [publicKey, connected]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const ref = urlParams.get('ref');

    if (ref) {
      setCookie(COOKIE_REF, ref);

      urlParams.delete('ref');

      const newUrl = urlParams.toString()
        ? `${window.location.pathname}?${urlParams.toString()}`
        : window.location.pathname;

      window.history.replaceState(null, '', newUrl);
    }
  }, []);

  useEffect(() => {
    loadUser();
  }, [publicKey, connected]);

  return children;
};

export default AuthProvider;
